#header .header-top {
  background-color: #d1d1d1;
}

#header .header-top .container {
  position: relative;
  border-bottom: 1px solid #2ea2cc;
  height: 44px;
}

#header .header-top .container .social-box {
  float: right;
  display: flex;
}

#header .header-top .container .social-box .box-fb {
  width: 35px;
  height: 35px;
  padding-top: 8px;
  padding-right: 37px;
  border-radius: 30px;
  font-size: 12px;
  margin-top: 5px;
  margin-right: 10px;
}

#header .header-top .container .social-box .box-fb:hover {
  background-color: #3b5a9a;
}

#header .header-top .container .social-box .box-linkedin {
  width: 35px;
  height: 35px;
  padding-top: 8px;
  padding-right: 37px;
  border-radius: 30px;
  font-size: 12px;
  margin-top: 5px;
}

#header .header-top .container .social-box .box-linkedin:hover {
  background-color: #0073b2;
}

#header .header-top .container .social-box a i {
  margin: 0 15px;
  color: #000;
  font-size: 14px;
}

#header .header-top .container .social-box a i:hover {
  color: #fff;
}

#header .container-fluid {
  background-color: #000;
}

#header .container-fluid .container .row {
  padding: 20px 0;
}

#header .container-fluid .container .row .box-search {
  position: relative;
}

#header .container-fluid .container .row .box-search input {
  border-radius: 1.25rem;
  padding-right: 45px;
}

#header .container-fluid .container .row .box-search i {
  position: absolute;
  top: 25%;
  right: 0;
}

#header .container-fluid .container .row .box-cart {
  position: relative;
}

#header .container-fluid .container .row .box-cart a {
  padding: 20px 0;
}

#header .container-fluid .container .row .box-cart a i {
  color: #fff;
}

#header .container-fluid .container .row .box-cart .box-cart-detail {
  display: none;
  background-color: #fff;
  padding: 15px;
  width: 300px;
  position: absolute;
  right: 2px;
  top: 60px;
  border: 1px solid #80808052;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  z-index: 999;
}

#header .container-fluid .container .row .box-cart .box-cart-detail #icon-sort {
  position: absolute;
  top: -12px;
  right: 34px;
  color: #fff;
  font-size: 30px;
}

#header .container-fluid .container .row .box-cart .box-cart-detail .count-item {
  background-color: #fff;
  color: #000;
  font-weight: 600;
}

#header .container-fluid .container .row .box-cart .box-cart-detail .description {
  text-align: center;
}

#header .container-fluid .container .row .box-cart .box-cart-detail .description a {
  background-color: #fff;
  border: none;
  color: grey;
  font-weight: 300;
}

#header .container-fluid .container .row .box-cart .box-cart-detail .view-cart {
  color: #000;
  float: right;
  padding: 0;
  font-weight: 600;
}

#header .container-fluid .container .row .box-cart .count {
  position: absolute;
  color: #fff;
  right: 24px;
  top: 13px;
  background-color: red;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding-left: 7px;
  padding-bottom: 15px;
  font-size: 11px;
}

#header .container-fluid .container .row .box-cart .btn-dropdown-cart {
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}

#box-menu .container #menu li a {
  font-weight: 600;
  padding-right: 15px;
  text-transform: uppercase;
}

#box-menu .container #menu li a:hover {
  color: red;
}

#box-menu .container #menu li a i {
  padding-right: 10px;
}

#box-menu .container #menu .btn-group #btnGroupDrop {
  color: #000;
}

#box-menu .container #menu .btn-group .dropdown-menu {
  margin-top: 10px;
}

#box-menu .container #menu .nav-item .box-language:hover {
  color: red;
}

#box-menu .container #menu .nav-item .box-language #dropdownLanguage {
  font-weight: 600;
}

#box-menu .container #menu .nav-item .box-language #dropdownLanguage:hover {
  color: red;
}

#box-menu .container #menu .nav-item .box-language .btn-dropdown-language {
  font-size: x-small;
}

#box-menu .container #menu .nav-item .box-language .btn-dropdown-language:hover {
  color: red;
}

#box-menu .container #menu .nav-item .box-language #menu-language {
  border-radius: 0;
  border: 1px solid #F8F9FA;
  position: absolute;
  inset: 5px auto auto 0 !important;
  margin: 0;
  transform: translate(0px, 40px);
}

#box-menu .container #menu .nav-item .box-language #menu-language li a {
  color: #777;
  text-decoration: none;
  border-bottom-color: #f3f3f3;
}

#box-menu .container #menu .nav-item .box-language #menu-language li .active {
  color: red;
  background-color: #f3f3f3 !important;
}

.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 10px 10px 285px;
  width: 100%;
  font-weight: 400;
  z-index: 999;
}

.header-fixed .nav-item .box-language:hover {
  color: red;
}

.header-fixed .nav-item .box-language #dropdownLanguage {
  font-weight: 600;
  color: #fff;
}

.header-fixed .nav-item .box-language #dropdownLanguage:hover {
  color: red;
}

.header-fixed .nav-item .box-language .btn-dropdown-language {
  position: absolute;
  top: 15px;
  right: 0;
  font-size: x-small;
  color: #fff;
}

.header-fixed .nav-item .box-language .btn-dropdown-language:hover {
  color: red;
}

.header-fixed .nav-item .box-language #menu-language {
  border-radius: 0;
  border: 1px solid gray;
  position: absolute;
  inset: 10px auto auto 0 !important;
  margin: 0;
  transform: translate(0px, 40px);
}

.header-fixed .nav-item .box-language #menu-language li a {
  color: #777;
  text-decoration: none;
  border-bottom-color: #f3f3f3;
}

.header-fixed .nav-item .box-language #menu-language li .active {
  color: red;
  background-color: #f3f3f3 !important;
}

.header-fixed #btnGroupDrop {
  color: #fff !important;
  font-size: 18px;
}

@media screen and (max-width: 380px) {
  #userMenu {
    left: -141px;
  }
}

.color-white {
  color: #fff;
}
