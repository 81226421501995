body {
    .category-list {
        border: 1px solid #e7e7e7;
        padding: 20px;

        .fa-angle-down,
        .fa-angle-up {
            position: absolute;
            right: 0;
            top: 6px;
            cursor: pointer;
        }

        h3 {
            font-weight: 600;
            font-size: 15px;
            color: #313131;
            text-transform: uppercase;
            transition: .25s;
            text-decoration: none;
            -webkit-line-clamp: 3;
            position: relative;

            .fa-angle-down,
            .fa-angle-up {
                top: 2px;
            }
        }

        ul {
            list-style: none;
            width: 100%;
            display: list-item;

            li {
                position: relative;

                .cat-name {
                    font-size: 14px;
                    font-weight: 500;
                    color: #7b858a;
                    text-decoration: none;
                    display: inline-block;
                    padding: 4px 0;

                    &.active,
                    &:hover {
                        color: #000;
                    }
                }
            }
        }

        .cat-level-2 {
            text-indent: 10px;
        }

        .cat-level-3 {
            text-indent: 20px;
        }

        .cat-level-4 {
            text-indent: 30px;
        }

        .cat-item {
            &.active > a {
                color: #313131;
            }
            &.active > .fa-angle-down {
                color: #313131;
            }
            &.active > .fa-angle-up {
                color: #313131;
            }
        }
    }

    .product-list {
        .product-item {
            a {
                text-decoration: none;
            }

            .thumb-info {
                min-height: 90px;
                margin-bottom: 0;

                .thumb-info-wrapper {
                    padding-top: 75%;
                    overflow: hidden;
                    height: 0;
                    position: relative;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;

                    img {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%) !important;
                        max-height: 100%;
                        width: auto !important;
                        height: 100%;
                        max-width: 100%;
                        position: absolute;
                    }
                }
            }

            h3 {
                text-overflow: ellipsis;
                max-width: 100%;
                color: #000;
                //text-transform: uppercase;
                margin-top: 10px;
                font-size: .9375rem;
                font-weight: 400;
                line-height: 1.35;
                letter-spacing: -.01em;
                margin-bottom: 0.45rem;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                white-space: normal !important;

                &.cat-title-limit {
                    //text-overflow: ellipsis;
                    //overflow: hidden;
                    //white-space: nowrap !important;
                    display: inline-block;
                    width: 100%;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: #222529;
                    margin-bottom: 20px;
                }
            }

            .product-cat-title {
                color: inherit;
                text-decoration: none;
                display: block;
                font-size: .625rem;
                opacity: .8;
                text-transform: uppercase;
                line-height: 1.7;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 400;
            }

            .price {
                display: block;
                font-size: 1.125rem;
                margin-bottom: 1rem;
                color: #444;
                line-height: 1;
                font-weight: 600;

                .price-value-original {
                    font-size: 0.8rem;
                    text-decoration: line-through;
                }
            }

            .rating-wrap {
                vertical-align: middle;
                margin: 0 0 0.625rem;
                position: relative;
                display: table;
            }

            .product-content {
                position: relative;
            }

            .product-favorite {
                position: absolute;
                top: 0px;
                right: 15px;
            }
        }
    }

    .title-limit {
        -webkit-box-orient: vertical !important;
    }
}