body {
    .product-detail {
        .product-title {
            font-size: 1.875rem;
            line-height: 1.2;
            color: #222529;
            letter-spacing: -.01em;
            font-weight: 700;
            margin-bottom: 0.2em;
            //text-transform: uppercase;
        }

        .product-photo {
            max-width: 100%;
        }

        .rating {
            &::after {
                content: '';
                display: block;
                width: 40px;
                border-top: 2px solid #e7e7e7;
                margin: 1rem 0 1.25rem;
            }
        }

        .price {
            font-size: 1.5rem;
            line-height: 1;
            font-weight: 600;
            letter-spacing: -.02em;
            color: #222529;

            .price-value-original {
                font-size: 0.8rem;
                text-decoration: line-through;
            }
        }

        .normal-text {
            color: #7b858a;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0;
        }

        .select-size {
            -webkit-appearance: none;
            -ms-appearance: none;
            -moz-appearance: none;
            background: #fff url('../../assets/images/select-bg.svg') 100% 49%/26px 60px no-repeat;
            text-indent: 0.01em;
            border-radius: 0;
            padding-right: 25px;
            display: block;
            height: 2.625rem;
            font-size: .8571em;
            font-weight: 600;
            text-transform: uppercase;
            box-shadow: none;
            width: 100%;
            background-color: #fff;
            color: #777;
            border-color: rgba(0,0,0,.09);
            border: 1px solid;
            outline: none;
            padding: 8px 12px;
            vertical-align: middle;
            border: 1px solid #e7e7e7;
        }
        
        .product-gallery {
            .list-product-gallery {
                margin-bottom: 5px;
            }
            .list-product-thumb {
                display: block;
                max-height: 84px;
                overflow: hidden;
                .gallery-item {
                    margin: 3px;
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    text-align: center;
                    width: 21%;
                    height: 72px;
                    border: 1px solid #ececec;
                    padding: 3px;
                    img {
                        width: auto;
                        height: 100%;
                        display: block;
                        margin: 0 auto;
                        -o-object-fit: cover;
                        object-fit: cover;
                        object-position: 50% 50%;
                        -o-object-position: 50% 50%;
                    }
                }
            }
        }

        .price-detail-promo {
            line-height: 2rem;
            padding-left: 25px;
            display: block;
            text-decoration: line-through !important;
        }
    }
}

.register-login-buy,
.gray-btn {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #222529;
    height: 3rem !important;
    padding: 0 2em;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: -.015em;
    font-weight: 700;
    line-height: 3rem;
    border: none;
    color: #ffffff;
    text-decoration: none;

    &:hover {
        color: #ffffff;
    }
}

.gray-btn {
    background-color: #f4f4f4;
    color: #222529;
    border: none;

    &:hover {
        color: #ffffff !important;
        background-color: #000000 !important;
    }
}

.mgb-10 {
    margin-bottom: 10px;
}

.mgb-15 {
    margin-bottom: 15px;
}

.mgb-20 {
    margin-bottom: 20px;
}

.mgb-25 {
    margin-bottom: 25px;
}

.mgr-15 {
    margin-right: 15px;
}