#header {
  .header-top {
    background-color: rgb(209, 209, 209);
    .container {
      position: relative;
      border-bottom: 1px solid #2ea2cc;
      height: 44px;

      .social-box {
        float: right;
        display: flex;

        .box-fb {
          width: 35px;
          height: 35px;
          padding-top: 8px;
          padding-right: 37px;
          border-radius: 30px;
          font-size: 12px;
          margin-top: 5px;
          margin-right: 10px;

          &:hover {
            background-color: #3b5a9a;
          }
        }

        .box-linkedin {
          width: 35px;
          height: 35px;
          padding-top: 8px;
          padding-right: 37px;
          border-radius: 30px;
          font-size: 12px;
          margin-top: 5px;

          &:hover {
            background-color: #0073b2;
          }
        }

        a {
          i {
            margin: 0 15px;
            color: #000;
            font-size: 14px;

            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .container-fluid {
    background-color: #000;

    .container {
      .row {
        padding: 20px 0;

        .box-search {
          position: relative;

          input {
            //margin: 20px 0 0 20px;
            border-radius: 1.25rem;
            padding-right: 45px
          }

          i {
            position: absolute;
            top: 25%;
            right: 0;
          }
        }

        .box-cart {
          position: relative;

          #shopping-bag {
            //position: absolute;
            //right: 50px;
          }

          a {
            padding: 20px 0;

            i {
              color:#fff;
            }
          }

          .box-cart-detail {
            display: none;
            background-color: #fff;
            padding: 15px;
            width: 300px;
            position: absolute;
            right: 2px;
            top: 60px;
            border: 1px solid #80808052;
            box-shadow: 0 5px 8px rgba(0, 0, 0 , 0.15);
            z-index: 999;

            #icon-sort {
              position: absolute;
              top: -12px;
              right: 34px;
              color: #fff;
              font-size: 30px;
            }

            .count-item {
              background-color: #fff;
              color: #000;
              font-weight: 600;
            }

            .description {
              text-align: center;
              a {
                background-color: #fff;
                border: none;
                color: grey;
                font-weight: 300;
              }
            }

            .view-cart {
              color: #000;
              float: right;
              padding: 0;
              font-weight: 600;
            }
          }

          .count {
            position: absolute;
            color: #fff;
            right: 24px;
            top: 13px;
            background-color: red;
            height: 20px;
            width: 20px;
            border-radius: 20px;
            padding-left: 7px;
            padding-bottom: 15px;
            font-size: 11px;
          }

          .btn-dropdown-cart {
            //position: absolute;
            //right: 16px;
            //top: 28px;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
          }
        }
      }
    }


  }
}

#box-menu {
  .container {
    //padding: 10px 5px;

    #menu {
      li {
        a {
          font-weight: 600;
          padding-right: 15px;
          text-transform: uppercase;

          &:hover {
            color: red;
          }

          i {
            padding-right: 10px;
          }
        }
      }
      .btn-group {
        #btnGroupDrop {
          color: #000;
        }

        .dropdown-menu {
          margin-top: 10px;
        }
      }
      .nav-item {
        .box-language {
          &:hover {
            color: red;
          }
          #dropdownLanguage {
            font-weight: 600;
            &:hover {
              color: red;
            }
          }
          .btn-dropdown-language {
            //position: absolute;
            //top: 15px;
            //right: 0;
            font-size: x-small;
            &:hover {
              color: red;
            }
          }
          #menu-language {
            border-radius: 0;
            border: 1px solid #F8F9FA;
            position: absolute;
            inset: 5px auto auto 0 !important;
            margin: 0;
            transform: translate(0px, 40px);
            li {
              a {
                color: #777;
                text-decoration: none;
                border-bottom-color: #f3f3f3;
              }
              .active {
                color: red;
                background-color: #f3f3f3 !important;
              }
            }
          }
        }
      }
    }
  }
}

.header-fixed {
  position: fixed;
  top:0;
  left:0;
  background-color: rgba(0,0,0,.8);
  padding: 10px 10px 10px 285px;
  width: 100%;
  font-weight: 400;
  z-index: 999;

  .nav-item {
    .box-language {
      &:hover {
        color: red;
      }
      #dropdownLanguage {
        font-weight: 600;
        color: #fff;
        &:hover {
          color: red;
        }
      }
      .btn-dropdown-language {
        position: absolute;
        top: 15px;
        right: 0;
        font-size: x-small;
        color: #fff;
        &:hover {
          color: red;
        }
      }
      #menu-language {
        border-radius: 0;
        border: 1px solid gray;
        position: absolute;
        inset: 10px auto auto 0 !important;
        margin: 0;
        transform: translate(0px, 40px);
        li {
          a {
            color: #777;
            text-decoration: none;
            border-bottom-color: #f3f3f3;
          }
          .active {
            color: red;
            background-color: #f3f3f3 !important;
          }
        }
      }
    }
  }

  #btnGroupDrop {
    color: #fff !important;
    font-size: 18px;
  }
}

@media screen and (max-width: 380px) {
  #userMenu {
    left: -141px;
  }
}

.color-white {
  color: #fff;
}